import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "rework-configuration"
    }}>{`rework Configuration`}</h1>
    <p>{`The rework configuration gives you the ability to customise the framework to your liking, and to enable plugins.`}</p>
    <p>{`By default, the configuration file should be named `}<inlineCode parentName="p">{`.reworkrc`}</inlineCode>{` and
be placed at the root of your project. (see also: `}<a parentName="p" {...{
        "href": "#specifying-the-path-to-the-configuration-file"
      }}>{`How to specifify the configiguration file path`}</a>{`)`}</p>
    <h2 {...{
      "id": "contents"
    }}>{`Contents`}</h2>
    <p>{`Example configuration file with all entries:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "routingType": "browser",
  "directories": {
    "logs": "./.build",
    "build": "./.build",
    "resources": "./src/public",
    "translations": "./src/translations"
  },
  "routes": "**/*.route.js",
  "entry-react": "./src/components/App",
  "render-html": "./src/render-html.js",
  "pre-init": "./src/pre-init.js",
  "service-worker": "./src/service-worker.js",
  "plugins": {
    "@reworkjs/redux": {}
  }
}
`}</code></pre>
    <p>{`All entries are optional
Paths are resolved from the location of your configuration file`}</p>
    <p><strong parentName="p">{`Important note:`}</strong>{` Changes to the configuration files will only take effect after the app has been restarted.`}</p>
    <h3 {...{
      "id": "routingtype"
    }}><inlineCode parentName="h3">{`routingType`}</inlineCode></h3>
    <p>{`Default: `}<inlineCode parentName="p">{`browser`}</inlineCode></p>
    <p>{`The type of router to use, see `}<a parentName="p" {...{
        "href": "https://reacttraining.com/react-router/web"
      }}>{`React-Router`}</a>{` documentation for more information on the types of routers.`}</p>
    <p>{`Possible values: `}<inlineCode parentName="p">{`browser`}</inlineCode>{` for `}<a parentName="p" {...{
        "href": "https://reacttraining.com/react-router/web/api/BrowserRouter"
      }}>{`BrowserRouter`}</a>{`, `}<inlineCode parentName="p">{`hash`}</inlineCode>{` for `}<a parentName="p" {...{
        "href": "https://reacttraining.com/react-router/web/api/HashRouter"
      }}>{`HashRouter`}</a></p>
    <h3 {...{
      "id": "emit-integrity"
    }}>{`'emit-integrity'`}</h3>
    <p>{`Default: `}<inlineCode parentName="p">{`true`}</inlineCode></p>
    <p>{`Emit `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Security/Subresource_Integrity"
      }}>{`subresource integrity`}</a>{` on generated assets.
This might cause issues when loading using the `}<inlineCode parentName="p">{`file://`}</inlineCode>{` protocol on some platforms, such as Cordova iOS.`}</p>
    <p>{`Note: Subresource integrity is always disabled in development mode.`}</p>
    <h3 {...{
      "id": "directoriesbuild"
    }}><inlineCode parentName="h3">{`directories.build`}</inlineCode></h3>
    <p>{`Default: `}<inlineCode parentName="p">{`./.build`}</inlineCode></p>
    <p>{`The directory in which compiled files will be outputted.`}</p>
    <p>{`server files will be located in `}<inlineCode parentName="p">{`{directories.build}/server`}</inlineCode>{`
client files will be located in `}<inlineCode parentName="p">{`{directories.build}/client`}</inlineCode></p>
    <h3 {...{
      "id": "directorieslogs"
    }}><inlineCode parentName="h3">{`directories.logs`}</inlineCode></h3>
    <p>{`Default: Value of `}<inlineCode parentName="p">{`directories.build`}</inlineCode></p>
    <p>{`The directory in which build & running logs will be outputted.`}</p>
    <h3 {...{
      "id": "directoriesresources"
    }}><inlineCode parentName="h3">{`directories.resources`}</inlineCode></h3>
    <p>{`Default: `}<inlineCode parentName="p">{`./src/public`}</inlineCode></p>
    <p>{`This directory contains assets that should not be transformed and will merely be copied over. (default: `}<inlineCode parentName="p">{`./src/public`}</inlineCode>{`)`}</p>
    <p>{`The files located inside of `}<inlineCode parentName="p">{`resources`}</inlineCode>{` will be copied inside of the `}<inlineCode parentName="p">{`public`}</inlineCode>{` directory in the output files.
Be careful not to use the name of a built resource (such as `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` or `}<inlineCode parentName="p">{`main.js`}</inlineCode>{`).`}</p>
    <p>{`See the chapter on `}<a parentName="p" {...{
        "href": "../4-public-resources.md"
      }}>{`Public Resources`}</a>{` for more information`}</p>
    <h3 {...{
      "id": "directoriestranslations"
    }}><inlineCode parentName="h3">{`directories.translations`}</inlineCode></h3>
    <p>{`Default: `}<inlineCode parentName="p">{`./src/translations`}</inlineCode></p>
    <p>{`This directory contains the translation files used by `}<inlineCode parentName="p">{`react-intl`}</inlineCode>{`. See the chapter about `}<a parentName="p" {...{
        "href": "../5-i18n.md"
      }}>{`i18n`}</a></p>
    <h3 {...{
      "id": "routes"
    }}><inlineCode parentName="h3">{`routes`}</inlineCode></h3>
    <p>{`Default: `}<inlineCode parentName="p">{`src/**/*.route.js`}</inlineCode></p>
    <p>{`A glob matching all files that should be interpreted as route definitions. See the chapter about `}<a parentName="p" {...{
        "href": "./routing.md"
      }}>{`routing`}</a>{` for more information.`}</p>
    <h3 {...{
      "id": "pre-init"
    }}><inlineCode parentName="h3">{`pre-init`}</inlineCode></h3>
    <p>{`Default: none`}</p>
    <p>{`This file allows you to specify code to run before the rest of your application is loaded. You can use it to load
dependencies needed by your application, such as polyfills.`}</p>
    <p>{`This file can have a single, optional, default export that is either a Promise, or a function (which optionally returns a Promise).`}</p>
    <p>{`If exporting a Promise, your application will be loaded after the promise resolves`}</p>
    <p>{`If exporting a Function, your application will be loaded after the execution of the function and after the Promise the function returns (if any) resolves.`}</p>
    <h2 {...{
      "id": "entry-react"
    }}><inlineCode parentName="h2">{`entry-react`}</inlineCode></h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "root-component.md"
      }}>{`The Root Component`}</a></p>
    <h3 {...{
      "id": "render-html"
    }}><inlineCode parentName="h3">{`render-html`}</inlineCode></h3>
    <p>{`TBD`}</p>
    <h3 {...{
      "id": "service-worker"
    }}><inlineCode parentName="h3">{`service-worker`}</inlineCode></h3>
    <p>{`Default: none`}</p>
    <p>{`If specified, the file will be loaded inside of the service worker.`}</p>
    <h2 {...{
      "id": "hooks"
    }}><inlineCode parentName="h2">{`hooks`}</inlineCode></h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "plugins.md#hook-system"
      }}>{`The first section of Plugins`}</a></p>
    <h2 {...{
      "id": "plugins"
    }}><inlineCode parentName="h2">{`plugins`}</inlineCode></h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "plugins.md#plugin-system"
      }}>{`The second section of Plugins`}</a></p>
    <h2 {...{
      "id": "specifying-the-path-to-the-configuration-file"
    }}>{`Specifying the path to the configuration file`}</h2>
    <p>{`Specify the `}<inlineCode parentName="p">{`--reworkrc`}</inlineCode>{` argument in the `}<inlineCode parentName="p">{`rjs`}</inlineCode>{` cli to change the used configuration file: `}<inlineCode parentName="p">{`rjs start --reworkrc=./app/.reworkrc`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      